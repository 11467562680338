import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import Images from "../assets/ServiceAreaHub.png"
// import AdminPage from '../adminPage/index';

function AdminNavBar(props){
  // let backDomain = "http://localhost:5000";
  // let adminDomain = "http://localhost:3000";
  // let frontDomain = "http://localhost:3000";
  // let backDomain = "https://serviceareahub.com/gutiimanaanbakendawaa";

  let adminDomain = "https://kriziyaadmin.serviceareahub.com";
  let backDomain = "https://sahbackend.onrender.com";
  let frontDomain = "https://serviceareahub.com";


  let token = localStorage.getItem("token");

  let navigate = useNavigate();

  const checkToken = () => {
    if (! token){
      let path = '/'; // replace with your desired path
      navigate(path); // Redirecting path
      // window.location.replace(adminDomain + "/chikadumpa"); // Redirecting path to admin login (with vanila js)
    }
  };
  checkToken();

    
  const logOut = () => {
      localStorage.removeItem("userType", "");
      localStorage.removeItem("token", "");
      token = "";
  };

  const del = (formID, required, assign) => {
    // const formID = evt;
    axios.delete(backDomain + "/form/deleteform/" + formID, {
      headers: {
        "token" : token
      }
    })
    .then(function (response) {
    
      if (response.data.message === "Invalid or removed, please Login to contiue"){
        let path = '/'; // replace with your desired path
        navigate(path); // Redirecting path
        // window.location.replace(adminDomain +"/chikadumpa"); // Redirecting path to admin login
      }
      else if (response.data.message === "ID not found OR the form has already been deleted!"){
        alert("The form not found.");
      }
      else if (response.data.message === "One form record has been removed successfully"){
        alert("Deleted successfully");
          
          let e = document.getElementById("adminPageContainer")
          //e.firstElementChild can be used. 
          let child = e.lastElementChild;
          while (child) {
            e.removeChild(child);
            child = e.lastElementChild;
          }
        formArrangement(required, assign);
      };
    });
  };
  
  const formArrangement = (required, assign) => { 
    if (assign === "multi"){
      axios.get(backDomain + "/form/receivedquery/"+required, {
        headers: {
          "token" : token
        }
      })
      .then(function (response) {
        // if (response.data.message === "Invalid or removed, please Login to contiue"){
        if (response.data.message === "Invalid or removed, please Login to contiue"){
          let path = '/'; // replace with your desired path
          navigate(path); // Redirecting path
          // window.location.replace(adminDomain +"/chikadumpa"); // Redirecting path to admin login
        }
        else{
          const dataCount = response.data.length;
          for (let i = 0; i < dataCount; i++){
            // Create element:
            const h5 = document.createElement("h5");
            h5.classList.add("card-title");
            h5.innerText = response.data[i].multiple_queries;
    
            const nameLi = document.createElement("li");
            nameLi.classList.add("list-group-item");
            nameLi.innerHTML = "Name : "+ response.data[i].client_name;
            const emailLi = document.createElement("li");
            emailLi.classList.add("list-group-item");
            emailLi.innerHTML = "Email : "+ response.data[i].client_email;
            const countryLi = document.createElement("li");
            countryLi.classList.add("list-group-item");
            countryLi.innerHTML = "Country : "+ response.data[i].client_country;
            const phoneLi = document.createElement("li");
            phoneLi.classList.add("list-group-item");
            phoneLi.innerHTML = "Phone : "+ response.data[i].client_phone;
            const idLi = document.createElement("li");
            idLi.classList.add("list-group-item");
            idLi.innerHTML = "ID : "+ response.data[i]._id;
            const dateLi = document.createElement("li");
            dateLi.classList.add("list-group-item");
            dateLi.innerHTML = "Date : "+ response.data[i].date;
            const ul = document.createElement("ul");
            ul.classList.add("list-group-item");
            ul.appendChild(nameLi);
            ul.appendChild(emailLi);
            ul.appendChild(countryLi);
            ul.appendChild(phoneLi);
            ul.appendChild(idLi);
            ul.appendChild(dateLi);
    
            const span = document.createElement("span");
            span.innerText = "Description : ";
            const para = document.createElement("p");
            para.classList.add("card-text");
            para.innerText = response.data[i].query_description;
            const paraDiv = document.createElement("div");
            paraDiv.classList.add("card-body");
            paraDiv.appendChild(span);
            paraDiv.appendChild(para);
    
            const deleteA = document.createElement("a");
            deleteA.classList.add("card-link");
            deleteA.classList.add();
            deleteA.innerHTML = "Delete";
            const anchorDiv = document.createElement("button");
            anchorDiv.classList.add("card-del-button");
            const idVal = response.data[i]._id;
            anchorDiv.setAttribute('id', idVal);
            anchorDiv.addEventListener('click', (e) => {
              const delVidID = anchorDiv.id;
              anchorDiv.onclick = function(event) {
                const confirmBox = window.confirm(
                  "Do you really want to delete this?"
                )
                if (confirmBox === true) {
                  del(delVidID, required, assign);
                  //   axios.delete(backDomain + "/deleteform/" + delVidID)
                }
              };
            });
            anchorDiv.appendChild(deleteA);
    
            const outerDiv = document.createElement("div");
            outerDiv.classList.add("card");
            // outerDiv.style.width = "80%";
            outerDiv.style.width = "18rem";
            outerDiv.appendChild(h5);
            outerDiv.appendChild(ul);
            outerDiv.appendChild(paraDiv);
            outerDiv.appendChild(anchorDiv);
    
            // Append to body:
            document.getElementById("adminPageContainer").appendChild(outerDiv);
          }
        };
      });
    }
    else if (assign === "single"){
      axios.get(backDomain + "/form/receivedexactquery/" + required,  {
        headers: {
          "token" : token
        }
      })
      .then(function (response) {
        
        if (response.data.message === "Invalid or removed, please Login to contiue"){
          let path = '/'; // replace with your desired path
          navigate(path); // Redirecting path
          // window.location.replace(adminDomain + "/chikadumpa"); // Redirecting path to admin login (with vanila js)
        }
        else{
          const dataCount = response.data.length;
          // console.log(response);
          for (let i = 0; i < dataCount; i++){
            // Create element:
            const h5 = document.createElement("h5");
            h5.classList.add("card-title");
            h5.innerText = response.data[i].multiple_queries;

            const nameLi = document.createElement("li");
            nameLi.classList.add("list-group-item");
            nameLi.innerHTML = "Name : "+ response.data[i].client_name;
            const emailLi = document.createElement("li");
            emailLi.classList.add("list-group-item");
            emailLi.innerHTML = "Email : "+ response.data[i].client_email;
            const countryLi = document.createElement("li");
            countryLi.classList.add("list-group-item");
            countryLi.innerHTML = "Country : "+ response.data[i].client_country;
            const phoneLi = document.createElement("li");
            phoneLi.classList.add("list-group-item");
            phoneLi.innerHTML = "Phone : "+ response.data[i].client_phone;
            const idLi = document.createElement("li");
            idLi.classList.add("list-group-item");
            idLi.innerHTML = "ID : "+ response.data[i]._id;
            const dateLi = document.createElement("li");
            dateLi.classList.add("list-group-item");
            dateLi.innerHTML = "Date : "+ response.data[i].date;
            const ul = document.createElement("ul");
            ul.classList.add("list-group-item");
            ul.appendChild(nameLi);
            ul.appendChild(emailLi);
            ul.appendChild(countryLi);
            ul.appendChild(phoneLi);
            ul.appendChild(idLi);
            ul.appendChild(dateLi);

            const span = document.createElement("span");
            span.innerText = "Description : ";
            const para = document.createElement("p");
            para.classList.add("card-text");
            para.innerText = response.data[i].query_description;
            const paraDiv = document.createElement("div");
            paraDiv.classList.add("card-body");
            paraDiv.appendChild(span);
            paraDiv.appendChild(para);

            const deleteA = document.createElement("a");
            deleteA.classList.add("card-link");
            deleteA.innerHTML = "Delete";
            const anchorDiv = document.createElement("button");
            anchorDiv.classList.add("card-del-button");
            const idVal = response.data[i]._id;
            anchorDiv.setAttribute('id', idVal);
            anchorDiv.addEventListener('click', (e) => {
              const delVidID = anchorDiv.id;
              anchorDiv.onclick = function(event) {
                const confirmBox = window.confirm(
                  "Do you really want to delete this?"
                )
                if (confirmBox === true) {
                  del(delVidID, required, assign);
                  //   axios.delete(backDomain + "/deleteform/" + delVidID)
                }
              };
            });
            anchorDiv.appendChild(deleteA);

            const outerDiv = document.createElement("div");
            outerDiv.classList.add("card");
            // outerDiv.style.width = "80%";
            outerDiv.style.width = "18rem";
            outerDiv.appendChild(h5);
            outerDiv.appendChild(ul);
            outerDiv.appendChild(paraDiv);
            outerDiv.appendChild(anchorDiv);

            // Append to body:
            document.getElementById("adminPageContainer").appendChild(outerDiv);
          }
        };
      });
    }
  }

  const [webDev, setWebDev] = useState("");
  const [digMrk, setDigMrk] = useState("");
  const [cntWrt, setCntWrt] = useState("");

  const handelSubmit = (evt) => {
    evt.preventDefault();
    
    let e = document.getElementById("adminPageContainer")
    //e.firstElementChild can be used. 
    let child = e.lastElementChild;
    while (child) {
      e.removeChild(child);
      child = e.lastElementChild;
    }

    let required = "";
    
    if (((webDev === true) && (digMrk === true) && (cntWrt === true))
    || ((webDev === true) && (digMrk === true))
    || ((webDev === true) && (cntWrt === true))
    || ((digMrk === true) && (cntWrt === true))
    || ((webDev !== true) && (digMrk !== true) && (cntWrt !== true))){

      if ((webDev === true) && (digMrk === true) && (cntWrt === true)) {
        required = "web_digital_content";
      }
      else if ((webDev === true) && (digMrk === true)) {
        required = "web_digital";
      }
      else if ((webDev === true) && (cntWrt === true)) {
        required = "web_content";
      }
      else if ((digMrk === true) && (cntWrt === true)) {
        required = "digital_content";
      };
  
      formArrangement(required, "multi");
    }

    else {
      if ((webDev === true) && (digMrk !== true) && (cntWrt !== true)){
        required = "web";
      }
      else if ((webDev !== true) && (digMrk === true) && (cntWrt !== true)){
        required = "digital";
      }
      else if ((webDev !== true) && (digMrk !== true) && (cntWrt === true)){
        required = "content";
      }
      
      formArrangement(required, "single");
    };
};

  return(
    <div>
      {/* <AdminPage/> */}
      <div id='adminPageContainer'/>

      <div id='adminNav'>
        {/* <Navbar expand="lg" className="bg-body-tertiary"> */}
        <Navbar expand="lg" className="bg-dark">
        <Container fluid>
        <nav className="navbar navbar-default">
          <div className="container-fluid">
            <div className="navbar-header">
            <Nav.Link className="navbar-brand" href={frontDomain} onClick={logOut}>
              {/* <a className="navbar-brand" href="https://serviceareahub.com" onClick={logOut}> */}
                {/* <img alt="Brand" src="..."> */}
                <img src={Images} alt="logo" className="logo" width="70px" height="70px" />
              </Nav.Link>
            </div>
          </div>
        </nav>
        <Navbar.Toggle  aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '60px' }} navbarScroll>
          <Nav.Link className="text-white" href={adminDomain + '/'} onClick={logOut}><p id="navMenu">Log Out</p>
          {/* <Nav.Link className="text-white" href="/chikadumpa" onClick={logOut}><p id="navMenu">Log Out</p> */}
          </Nav.Link>
                            
        </Nav>
            <Form onSubmit={handelSubmit} className="d-flex">
             
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="webDevCheckBox" value={webDev} onChange={(e) => setWebDev(e.target.checked)}/>
                <label class="form-check-label" for="webDevCheckBox">
                  Web Development
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="digitalMarketingCheckBox" value={digMrk} onChange={(e) => setDigMrk(e.target.checked)}/>
                <label class="form-check-label" for="digitalMarketingCheckBox">
                  Digital Marketing
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="contentWritingCheckBox" checked={cntWrt} onChange={(e) => setCntWrt(e.target.checked)}/>
                <label class="form-check-label" for="contentWritingCheckBox">
                  Content Writing
                </label>
              </div>
              {/* <Button variant="outline-success" id="SearchButton">Search</Button> */}
              <input type="submit" value="Search" variant="outline-success" id="SearchButton"/>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
      
    </div>
  );
};

export default AdminNavBar;