import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./style.css";


const AdminLogIn = () => {
    // let backDomain = "http://localhost:5000";
    // let adminDomain = "http://localhost:3000";
    // let frontDomain = "http://localhost:3000";
    // let backDomain = "https://serviceareahub.com/gutiimanaanbakendawaa";


    // Admin sub-portal ----------> https://kriziyaadmin.serviceareahub.com   <---------------------------------------------------
    // let frontDomain = "https://serviceareahub.com";
    
    // let adminDomain = "https://kriziyaadmin.serviceareahub.com";
    let backDomain = "https://sahbackend.onrender.com";

    
    // function SignUp() {
    //     location.replace("https://www.service.com/signup")
    //   }
    const [userID, setUserID] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");

    
    let navigate = useNavigate();

    const handelSubmit = (evt) => {

        evt.preventDefault();
        if ((userID !== "") && (password !== "")) {
            if (userID.length < 9){
                setMsg("User ID should be more than 8 charecters");   
            }
            else{
                setMsg("");
                const userData = {
                    uid : userID,
                    pswd : password
                };
                axios.post(backDomain + "/adm852/741signin963", userData).then((response) => {
                    // console.log(response.status, response.data.message, response.data.activeadmin, response.data.token);
                    if (response.data.message === "Success"){
                        localStorage.setItem("userType", response.data.activeadmin);
                        localStorage.setItem("token", response.data.token);
                        
                        let path = '/amadigologinbad'; // replace with your desired path
                        navigate(path); // Redirecting path
                        // window.location.replace(adminDomain + "/amadigologinbad"); // Redirecting path (with vanila js)
                        // window.location.replace(adminDomain + "/chikadumpa/amadigologinbad"); // Redirecting path (with vanila js)
                    }
                    else{
                        setMsg(response.data.message);
                        // setMsg("Oops! Wrong credintial. Please try again.");
                    }
                });
            }
        }

        else {
            setMsg("Oops! Wrong credintial. Please try again.");
        }

        setUserID("");
        setPassword("");
    }
    
    
  return (
    <div>
        <form onSubmit={handelSubmit} className="container">
            <div>
                <input type="text" placeholder="Enter your registered User ID" value={userID} onChange={(e) => setUserID(e.target.value)} autoFocus required className="textBox" />
                {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                <br />
                <br />
                <input type="password" placeholder="Put your password" value={password} onChange={(e) => setPassword(e.target.value)} required className="textBox" />
            </div>
            <br />
            <br />
            <div>
                <input type="submit" value="Log In" className="LogIn" />
            </div>
            <br />
            <p className="msg"><b>{msg}</b></p>
            <br />
            <br />
            {/* <div>New User? <NavLink to="/signup" className="SignUpLink">Register Me Now!</NavLink></div> */}
        </form>
    </div>
  )
}

export default AdminLogIn;