import './App.css';
// import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogIn from './components/adminLogin/index';
import AdminNavBar from './components/adminNavbar/index';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AdminLogIn />}></Route>
        <Route path='/amadigologinbad' element={<AdminNavBar />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
